// GENERATED FILE - DO NOT MODIFY
import { AddUserToConcessionRequestDto } from 'Api/Features/Concessions/Dtos/AddUserToConcessionRequestDto';
import { ConcessionDetailsDto } from 'Api/Features/Concessions/Dtos/ConcessionDetailsDto';
import { GetConcessionsPublicRequestDto } from 'Api/Features/Concessions/Dtos/GetConcessionsPublicRequestDto';
import { GetConcessionsPublicResponseDto } from 'Api/Features/Concessions/Dtos/GetConcessionsPublicResponseDto';
import { GetConcessionsRequestDto } from 'Api/Features/Concessions/Dtos/GetConcessionsRequestDto';
import { GetConcessionsResponseDto } from 'Api/Features/Concessions/Dtos/GetConcessionsResponseDto';
import { GetMakesResponseDto } from 'Api/Features/Concessions/Dtos/GetMakesResponseDto';
import { GetRecruitmentConcessionsPublicRequestDto } from 'Api/Features/Concessions/Dtos/GetRecruitmentConcessionsPublicRequestDto';
import { GetRecruitmentConcessionsPublicResponseDto } from 'Api/Features/Concessions/Dtos/GetRecruitmentConcessionsPublicResponseDto';
import { GetRecruitmentConcessionsRequestDto } from 'Api/Features/Concessions/Dtos/GetRecruitmentConcessionsRequestDto';
import { GetRecruitmentConcessionsResponseDto } from 'Api/Features/Concessions/Dtos/GetRecruitmentConcessionsResponseDto';
import { RecruitmentConcessionPublicDetailsDto } from 'Api/Features/Concessions/Dtos/RecruitmentConcessionPublicDetailsDto';
import { RemoveUserFromConcessionRequestDto } from 'Api/Features/Concessions/Dtos/RemoveUserFromConcessionRequestDto';
import { UpdateConcessionRecruitmentInfoRequestDto } from 'Api/Features/Concessions/Dtos/UpdateConcessionRecruitmentInfoRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class ConcessionProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getConcession(concessionId: string): Promise<ConcessionDetailsDto | null> {
        const uri = this.buildUri(
            "/concessions/{concessionId}",
            { concessionId: concessionId },
            null
        );

        const data: ConcessionDetailsDto | null = await this.httpClient.get<ConcessionDetailsDto | null>(uri);
        return data;
    }

    public async getConcessions(request: GetConcessionsRequestDto | null): Promise<GetConcessionsResponseDto | null> {
        const uri = this.buildUri(
            "/concessions",
            null,
            { ...request || undefined }
        );

        const data: GetConcessionsResponseDto | null = await this.httpClient.get<GetConcessionsResponseDto | null>(uri);
        return data;
    }

    public async updateConcessionRecruitmentInfo(concessionId: string, request: UpdateConcessionRecruitmentInfoRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/concessions/{concessionId}/recruitment-info",
            { concessionId: concessionId },
            null
        );

        await this.httpClient.patch<UpdateConcessionRecruitmentInfoRequestDto | null, void>(uri, request);
    }

    public async getConcessionsPublic(request: GetConcessionsPublicRequestDto | null): Promise<GetConcessionsPublicResponseDto | null> {
        const uri = this.buildUri(
            "/concessions-public",
            null,
            { ...request || undefined }
        );

        const data: GetConcessionsPublicResponseDto | null = await this.httpClient.get<GetConcessionsPublicResponseDto | null>(uri);
        return data;
    }

    public async getRecruitmentConcessions(request: GetRecruitmentConcessionsRequestDto | null): Promise<GetRecruitmentConcessionsResponseDto | null> {
        const uri = this.buildUri(
            "/recruitment-concessions",
            null,
            { ...request || undefined }
        );

        const data: GetRecruitmentConcessionsResponseDto | null = await this.httpClient.get<GetRecruitmentConcessionsResponseDto | null>(uri);
        return data;
    }

    public async getRecruitmentConcessionPublic(concessionId: string): Promise<RecruitmentConcessionPublicDetailsDto | null> {
        const uri = this.buildUri(
            "/recruitment-concessions-public/{concessionId}",
            { concessionId: concessionId },
            null
        );

        const data: RecruitmentConcessionPublicDetailsDto | null = await this.httpClient.get<RecruitmentConcessionPublicDetailsDto | null>(uri);
        return data;
    }

    public async getRecruitmentConcessionsPublic(request: GetRecruitmentConcessionsPublicRequestDto | null): Promise<GetRecruitmentConcessionsPublicResponseDto | null> {
        const uri = this.buildUri(
            "/recruitment-concessions-public",
            null,
            { ...request || undefined }
        );

        const data: GetRecruitmentConcessionsPublicResponseDto | null = await this.httpClient.get<GetRecruitmentConcessionsPublicResponseDto | null>(uri);
        return data;
    }

    public async getMakes(): Promise<GetMakesResponseDto | null> {
        const uri = this.buildUri(
            "/makes",
            null,
            null
        );

        const data: GetMakesResponseDto | null = await this.httpClient.get<GetMakesResponseDto | null>(uri);
        return data;
    }

    public async addUserToConcession(concessionId: string, request: AddUserToConcessionRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/concessions/{concessionId}/add-user",
            { concessionId: concessionId },
            null
        );

        await this.httpClient.post<AddUserToConcessionRequestDto | null, void>(uri, request);
    }

    public async removeUserFromConcession(concessionId: string, request: RemoveUserFromConcessionRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/concessions/{concessionId}/remove-user",
            { concessionId: concessionId },
            null
        );

        await this.httpClient.post<RemoveUserFromConcessionRequestDto | null, void>(uri, request);
    }
}