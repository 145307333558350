import Copydeck from 'i18n/Copydeck';
import './index.less';
import TaglessStaticMultiSelect from 'Components/select-custom/multi-select/tagless-static-multi-select';
import Button from 'Components/button';
import MetierCard from 'Components/metier-card';
import { useHistory, useLocation } from 'react-router-dom';
import useMetiersRendering from 'Hooks/use-metiers-rendering';
import { useStores } from 'Hooks';
import { METIER_CATEGORIES_PARAM, METIER_URL, QUIZ_URL } from 'Models/Constants';
import { observer } from 'mobx-react';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MetiersCategory } from 'Models/MetierDefinitions';
import { autorun } from 'mobx';

const MetiersList = observer(() => {
    const {
        userStore: { preferredJobCategory, userQuizAnswers },
    } = useStores();
    const { t } = useTranslation();
    const history = useHistory<{ categories: string[] }>();
    const location = useLocation<{ categories: string[] }>();
    const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

    const selectPlaceholder = useMemo(
        () =>
            preferredJobCategory
                ? Copydeck.metiersMetiersFilterJobForYou
                : Copydeck.metiersMetiersFilterAllCategories,
        [preferredJobCategory]
    );

    const getInitialCategoriesFromSearchParams = () => {
        const paramCategories = searchParams.get(METIER_CATEGORIES_PARAM);
        if (paramCategories) {
            const categoriesArray = paramCategories.split(',').map((cat) => cat.trim());
            return categoriesArray;
        } else return [];
    };

    const {
        handleFilterChange,
        selectedMetiers,
        categories,
        selectedCategories,
        setSelectedCategories,
    } = useMetiersRendering({
        preferredJobCategory,
        initialCategories: getInitialCategoriesFromSearchParams(),
    });

    useEffect(() => {
        const metiersState = location.state;
        if (metiersState?.categories) {
            setSelectedCategories(metiersState.categories);
        }
    }, [location.state, setSelectedCategories]);

    useEffect(() => {
        const disposer = autorun(() => {
            if (selectedCategories.length === 0) {
                searchParams.delete(METIER_CATEGORIES_PARAM);
                return history.replace({ search: searchParams.toString() });
            }
            if (selectedCategories.includes(MetiersCategory.all)) {
                searchParams.set(METIER_CATEGORIES_PARAM, t(MetiersCategory.all));
            } else {
                searchParams.set(
                    METIER_CATEGORIES_PARAM,
                    selectedCategories.map((x) => t(x)).join(', ')
                );
            }

            history.replace({ search: searchParams.toString() });
        });

        return () => {
            disposer();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCategories, t]);

    return (
        <div className="MetiersList">
            <div className="MetiersList__content">
                <div className="MetiersList__topRow">
                    <h2 className="MetiersList__title">{Copydeck.metiersMetiersDecouverteTitle}</h2>
                    <Button
                        text={
                            userQuizAnswers !== null ? t('refaire_mon_quiz') : t('faire_mon_quiz')
                        }
                        type="red"
                        className="MetiersList__cta"
                        onClick={() => history.push(QUIZ_URL)}
                    />
                    <label className="MetiersList__selectInputContainer">
                        <span className="MetiersList__selectInputLabel">
                            {Copydeck.metiersMetiersFilterBy}
                        </span>
                        <TaglessStaticMultiSelect
                            blueStyle
                            className="MetiersList__selectInput"
                            options={categories}
                            selected={selectedCategories}
                            onChange={handleFilterChange}
                            placeholder={selectPlaceholder}
                            placeholderOnSelectedOptions={Copydeck.categorie}
                            closeMenuOnSelect={false}
                            strongLabel
                        />
                    </label>
                </div>
                <div className="MetiersList__list">
                    {selectedMetiers.map(({ description, image, ...x }, index) => (
                        <MetierCard
                            description={description.p1}
                            {...x}
                            key={index}
                            className="MetiersList__metierCard"
                            onClick={() =>
                                history.push(METIER_URL + `/${x.to}`, {
                                    categories: selectedCategories,
                                })
                            }
                            image={image.small}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
});

export default MetiersList;
